import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VnToastsComponent } from './vn-toasts.component';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    VnToastsComponent
  ],
  imports: [
    CommonModule,
    NgbToastModule,
  ],
  exports: [
    VnToastsComponent
  ]
})
export class VnToastsModule { }

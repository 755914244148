import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}


https://github.com/angular/angular/issues/41192
platformBrowserDynamic().bootstrapModule(AppModule, { ngZoneEventCoalescing: true })
  .catch(err => console.error(err));


// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));

import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

// import { FullLayoutComponent } from "./layouts/full/full-layout.component";
// import { ContentLayoutComponent } from "./layouts/content/content-layout.component";

// import { Full_ROUTES } from "./shared/routes/full-layout.routes";
// import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";

// const routes: Routes = [
//   {
//     path: '',
//     redirectTo: 'dashboard/e-commerce',
//     pathMatch: 'full',
//   },
//   { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES },
//   { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES },
//   { path: '**', redirectTo: 'dashboard/e-commerce' }
// ];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () => import('src/app/pages/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'login',
    loadChildren: () => import('src/app/pages/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'global-admin-landing-page',
    loadChildren: () => import('src/app/pages/global-admin-landing-page/global-admin-landing-page.module').then(m => m.GlobalAdminLandingPageModule),
  },
  {
    path: 'login/:redirectTo',
    loadChildren: () => import('src/app/pages/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'not-found',
    loadChildren: () => import('src/app/pages/not-found/not-found.module').then(m => m.NotFoundModule),
  },
  {
    path: 'forbidden',
    loadChildren: () => import('src/app/pages/forbidden/forbidden.module').then(m => m.ForbiddenModule),
  },
  {
    path: '**',
    redirectTo: 'not-found'
  }
];

const config: ExtraOptions = {
  useHash: false,
  //enableTracing: true
};
@NgModule({
  //imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

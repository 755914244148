import { Injectable } from '@angular/core';
import { Constants } from 'src/app/config/constants';

@Injectable({
  providedIn: 'root'
})
export class ApiVcpBase {

  constructor(
  ) { }

  /**
   * @description Downloads as a JsonFile the obj passed in the args. Used to report issues while debugging
   * @param {*} obj
   * @memberof ApiVcpBase
   */
  downloadJson(obj: any): void {
    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([JSON.stringify(obj, null, 2)], {
      type: "text/plain"
    }));
    a.setAttribute("download", "data.json");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  /**
   * @description Returns a fully qualified URL using the url of the server for the API
   * @protected
   * @param {string} pathMethod
   * @return {*}  {string}
   * @memberof ApiVcpBase
   */
  protected _createUrl(pathMethod: string): string {
    return `${Constants.API_VSBLTY_URL}/${pathMethod}`;;
  }


  /**
   * @description Validates if  a fully qualified URL using a filename on group container
   * @protected
   * @param {string} groupId
   * @param {string} filePath
   * @return {*}  {string}
  */
  protected isUrl(uri){
    return (uri != '' && (/(?:(?:http|https|ftp):\/\/)/.test(uri) || uri.startsWith("data:")));
  }
}
export interface ServerResponse {
  Exception: null,
  ExceptionMessage: null,
  Success: boolean,
  Text: string,
}
export interface ApiServerResponse {
  Success: boolean,
  ErrorMessage?: string,
  Text?: string,
  StatusCode: number,
  Data?: any
}

export interface PageData<T> {
  HasNext: boolean,
  TotalElements: number,
  TotalPages: number,
  Data: T | null,
  Message?: {
    Success: boolean,
    Text: string,
    Exception?: string,
  }
}

export class SearchOption {
  constructor(
    private propertyName: string,
    private textSearch: string,
  ) { }
}
export class ApiPaginationConfig {
  private searchOptions?: string;
  constructor(
    private textSearch?: string,
    private sortProperty?: string,
    private sortOrder?: string,
    private pageSize?: number,
    private page?: number,
    searchOptions?: Array<SearchOption>,
    private isExactSearch?: boolean,
  ) {
    this.pageSize = this.pageSize == undefined ? 10 : this.pageSize;
    this.page = this.page == undefined ? 0 : this.page;
    if (searchOptions != null && searchOptions.length > 0) {
      this.searchOptions = JSON.stringify(searchOptions);
    }
    this.isExactSearch = this.isExactSearch == undefined ? false : this.isExactSearch;
  }
}

export interface EntityName {
  Id: string,
  Name: string,
}

<ngb-toast class="fade"
           *ngFor="let toast of toastService.toasts"
           [class.bg-success]="toast.type == 'success'"
           [class.bg-info]="toast.type == 'info'"
           [class.bg-warning]="toast.type == 'warn'"
           [class.bg-danger]="toast.type == 'error'"
           [class.text-white]="toast.type == 'success' || toast.type == 'error'"
           [class.text-dark]="toast.type == 'info' || toast.type == 'warn'"
           [header]="toast.header"
           [autohide]="true"
           [delay]="toast.delay"
           (hidden)="toastService.remove(toast)">
  <span [class.text-white]="toast.type == 'success' || toast.type == 'error'"
        [class.text-dark]="toast.type == 'info' || toast.type == 'warn'">{{toast.body}}</span>
</ngb-toast>

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { Constants } from '../config/constants';
import { catchError, filter, switchMap, takeWhile, tap } from 'rxjs/operators';
import { ApiAuthService } from '../core/services/api/api-auth.service';
import { Router } from '@angular/router';
import { VnToastService } from '../components/vn-toasts/vn-toasts.service';
import { Location } from '@angular/common';


@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {

  private _redirectToLogin: boolean = false;

  constructor(
    private _apiAuthService: ApiAuthService,
    private _router: Router,
    private _toastService: VnToastService,
    private _location: Location,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //let req = this._setUpHeaders(request);

    ///      ^                            ^
    ///     / \                          / \
    ///    / | \         NOTICE         / | \
    ///   /  o  \                      /  o  \
    ///  /_______\                    /_______\
    ///
    /// The implementation of authorization headers with an interceptor was used and was working until
    /// uploading of an image was necessary. The interceptor had no issues sending the data to the .net API,
    /// however, the Thingsboard API, which is based off JAVA, was having issues with it and not allowing
    /// the uploading of files. All kinds of changes were tried, and even an issue on stackoverflow similar to
    /// the one described was found, but with no answers ->
    /// https://stackoverflow.com/questions/59144968/angular-2-upload-file-interceptor-issue)
    /// As such, the dealing with headers and auth headers was decided to be implemented in the API service wrapper
    /// so the headers can be created or modified even before the requests begging.
    ///

    return next.handle(request)
      .pipe(
        takeWhile(val => !this._redirectToLogin),
        // There may be other events besides the response.
        filter(event => event instanceof HttpResponse || event instanceof HttpErrorResponse),
        tap(
          (event: HttpResponse<any>) => {
            //console.log('event', event);
          },
          (error: HttpErrorResponse) => {
            //console.log('error response', error);
            let errorInfo = { ...this._parseError(error) };

            // // executed when fetching activation links for a user that is already activated
            // if (errorInfo?.status == 400 && errorInfo?.error?.errorCode == 31 && errorInfo?.error?.message == 'User is already active!') {

            // }



            // if (errorInfo.message.includes('Http failure response for') && typeof errorInfo.error !== 'string') {
            //   this._toastService.error(errorInfo.message);
            // }


            if (errorInfo.error == undefined) {
              this._toastService.error(errorInfo.message);
            }

            // detecting if user doesn't have permissions
            if (errorInfo.message == "You don't have permission to perform this operation!") {
              this._toastService.error(errorInfo.message);
            }

            // detecting if token is expired
            if ((errorInfo.error.errorCode == 11 && errorInfo.error.status == 401) ||
              // detecting if wrong username or password
              (errorInfo.error.errorCode == 10 && errorInfo.error.status == 401) ||
              (errorInfo.error.message == 'Unauthorized' && errorInfo.status == 401 && errorInfo.url.includes(Constants.API_VSBLTY_URL))
            ) {
              this._redirectToLogin = true;
            }
            return errorInfo;
          }
        ),
        switchMap(
          (req) => {
            return of(req);
          }
        ),
        catchError(
          (error: HttpErrorResponse): Observable<any> => {
            let errorInfo = { ...this._parseError(error) };
            //return throwError(errorInfo);

            if (!this._redirectToLogin) {
              return throwError(errorInfo);
            } else {
              this._toastService.error(errorInfo.error.message);
              return of(this._apiAuthService.logout()
                .subscribe(
                  success => {
                    //console.log('this._location.path()', this._location.path());
                    //this._errorMessage = errorInfo;
                    this._redirectToLogin = false;
                    // this._router.navigate(['/login'], { queryParams: { redirectUrl: this._router.url }, queryParamsHandling: 'preserve' });
                    this._router.navigate(['/login'], { queryParams: { redirectUrl: this._location.path() } })
                  }
                )
              );
            }
          }),
      )
  }

  private _isJson(str: string): boolean {
    try {
      JSON.parse(str);
    }
    catch (error) {
      return false;
    }
    return true;
  }

  private _parseError(error) {
    if (typeof error?.error == 'string' && this._isJson(error?.error)) {
      error.error = JSON.parse(error.error);
    }
    return error;
  }
}

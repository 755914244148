// Angular Modules
import { TooltipOptions } from 'ng2-tooltip-directive';
import Config from '../../config.json';
import { environment } from '../../environments/environment';

export abstract class Constants {
  private static readonly API_FACE_ENROLLMENT_ENDPOINT: string = "https://vnext2.vsblty.net/face-enrollment/api";
  private static readonly API_THINGSBOARD_ENDPOINT: string = "https://vnext2.vsblty.net/api";
  private static readonly API_CMS_ENDPOINT: string = "https://v-next.vsblty.net/api";

  public static get API_FACE_ENROLLMENT_URL(): string {
    return (Constants.Config.API.FaceEnrollment || Constants.API_FACE_ENROLLMENT_ENDPOINT);
  }

  public static get API_THINGSBOARD_URL(): string {
    return (Constants.Config.API.ThingsBoard || Constants.API_THINGSBOARD_ENDPOINT);
  }

  public static get API_VSBLTY_URL(): string {
    return (Constants.Config.Debug ? Constants.Config.API.CMSLocal : Constants.Config.API.CMSEndpoint) || Constants.API_CMS_ENDPOINT;
  }

  public static get TOOLTIP_OPTIONS(): TooltipOptions {
    return { 'show-delay': Constants.Config.Components.Tooltips.ShowDelay };
  }

  public static get SEARCH_DELAY_TIME(): number {
    return Constants.Config.Components.Search.Delay;
  }

  public static get API_TOOL_MAX_THREADS(): number {
    return Config.Components.ApiTool.MaxThreads || 1;
  }

  public static get IMAGES() {
    return {
      thumb: {
        video: 'assets/images/designer/video-1.svg',
        audio: 'assets/images/designer/audio-1.svg',
        image: 'assets/images/designer/camera-3.svg',
        url: 'assets/images/designer/url-1.svg',
        html: 'assets/images/designer/url.png',
        vast: 'assets/images/designer/external-url-1.svg',
        perpetual: 'assets/images/designer/url-1.svg',
        interactive: 'assets/images/designer/url-2.svg',
        playlist: 'assets/images/designer/playlist-1.svg',
        transparent1x1: 'assets/images/designer/1x1-transparent.png',
      }
    }
  }

  public static get IS_PRODUCTION(): boolean {
    return environment.production;
  }

  private static __config = null;
  protected static get Config(){
    if(!Constants.__config) {
      var env = window["env"];
      if(env){
        var token = env["token"];
        if(token){
          try{
            var data = JSON.parse(atob(token));
            Constants.__config = Object.assign(Config, data);
            console.debug("Environment has been initialized", Constants.__config);
            env["token"] = null; // disposes environment token
            return Constants.__config;
          }catch(e){
            console.warn("Error while loading environment", e);
          }
        }
      }

      // Set default config
      Constants.__config = Config;
      console.debug("Loading Default Environment Values", data);
    }
    return Constants.__config;
  }
}

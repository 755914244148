import { Injectable } from '@angular/core';
import { ApiHttpService } from './api-http.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { Constants } from '../../../config/constants';
import { BaseURLParams } from 'src/app/shared/interfaces/base-params';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class ApiAuthService {

  private _cookieConfig: {
    path?: string,
    domain?: string,
    secure?: boolean,
    sameSite?: "Lax" | "None" | "Strict"
  } = {
      path: '/',
      domain: location.hostname,
      secure: location.protocol === 'https:',
      sameSite: 'Lax',
    };
  constructor(
    protected _apiHttpService: ApiHttpService,
    protected _cookieService: CookieService,
  ) { }

  private _authHandler(response: IAuthResponse): boolean {
    this._setCookie('token', response.token);
    this._setCookie('refreshToken', response.refreshToken);
    // if the user that just logged in is a sys_admin, we store the creds in a different cookie
    // so it can be retrieved when the admin switches between users
    let user: User = jwt_decode(response.token);
    if (user.scopes.indexOf(UserScopes.SYS_ADMIN) != -1) {
      this._setCookie('admin_token', response.token);
      this._setCookie('admin_refreshToken', response.refreshToken);
    }
    return true;
  }

  authenticate(username: string, password: string, rememberMe: boolean): Observable<boolean> {
    let params: BaseURLParams = {
      url: Constants.API_THINGSBOARD_URL + '/auth/login',
      body: {
        username: username,
        password: password
      }
    }
    return this._apiHttpService.post(params)
      .pipe(
        map((data: IAuthResponse) => { return this._authHandler(data); }),
        catchError(this._apiHttpService.handleError)
      );
  }

  refreshToken(): Observable<boolean> {
    let params: BaseURLParams = {
      url: Constants.API_THINGSBOARD_URL + '/auth/token',
      body: {
        refreshToken: this._getUserLoginCookies().refreshToken,
      }
    }
    return this._apiHttpService.post(params)
      .pipe(
        map((data: IAuthResponse) => { return this._authHandler(data); }),
        catchError(this._apiHttpService.handleError)
      );
  }

  logout(): Observable<boolean> {
    this._deleteAdminLoginCookies();
    this._deleteUserLoginCookies();
    return of(true);
  }

  switchToUser(userTokenData: IAuthResponse): Observable<boolean> {
    this._setAdminLoginCookies(this._getUserLoginCookies());
    this._setUserLoginCookies(userTokenData);
    return of(true);
  }

  switchToAdmin(): Observable<boolean> {
    this._setUserLoginCookies(this._getAdminLoginCookies());
    return of(true);
  }

  getUserToken(): string {
    return this._getUserLoginCookies().token;
  }

  getAdminToken(): string {
    return this._getAdminLoginCookies().token;
  }

  private _setCookie(name: string, value: any, expires: number = 0) {
    this._cookieService.set(
      name,
      value,
      expires,
      this._cookieConfig.path,
      this._cookieConfig.domain,
      this._cookieConfig.secure,
      this._cookieConfig.sameSite
    );
  }

  private _deleteCookie(name: string) {
    this._cookieService.delete(
      name,
      this._cookieConfig.path,
      this._cookieConfig.domain,
      this._cookieConfig.secure,
      this._cookieConfig.sameSite
    );
  }

  private _getUserLoginCookies(): IAuthResponse {
    let token = this._cookieService.get('token');
    token = token != null && token != '' ? token : null;
    let refreshToken = this._cookieService.get('refreshToken');
    refreshToken = refreshToken != null && refreshToken != '' ? refreshToken : null;
    return {
      token: token,
      refreshToken: refreshToken
    }
  }
  private _setUserLoginCookies(tokenData: IAuthResponse) {
    this._setCookie('token', tokenData.token);
    this._setCookie('refreshToken', tokenData.refreshToken);
  }
  private _deleteUserLoginCookies() {
    this._deleteCookie('token');
    this._deleteCookie('refreshToken');
  }

  private _getAdminLoginCookies(): IAuthResponse {
    let token = this._cookieService.get('admin_token');
    token = token != null && token != '' ? token : null;
    let refreshToken = this._cookieService.get('admin_refreshToken');
    refreshToken = refreshToken != null && refreshToken != '' ? refreshToken : null;
    return {
      token: token,
      refreshToken: refreshToken
    }
  }
  private _setAdminLoginCookies(tokenData: IAuthResponse) {
    this._setCookie('admin_token', tokenData.token);
    this._setCookie('admin_refreshToken', tokenData.refreshToken);
  }
  private _deleteAdminLoginCookies() {
    this._deleteCookie('admin_token');
    this._deleteCookie('admin_refreshToken');
  }
}

export interface IAuthResponse {
  token: string,
  refreshToken: string
}

export enum UserScopes {
  SYS_ADMIN = 'SYS_ADMIN',
  TENANT_ADMIN = 'TENANT_ADMIN',
  CUSTOMER_USER = 'CUSTOMER_USER',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
}

export interface User {
  sub: string, // email address,
  scopes: Array<UserScopes>,
  userId: string,
  firstName: string,
  lastName: string,
  enabled: boolean,
  isPublic: boolean,
  tenantId: string,
  customerId: string,
  iss: string // url,
  iat: number,
  exp: number,
  thumbUrl: string, //used locally
}
